export default 
{
    "options": [{
        "title": "face",
        "order": 3,
        "elements": [
            {
                "id": 101,
                "title": "Aurélien",
                "path": "img/faces/01.svg",
                "position": {
                    "x": 12,
                    "y": 12
                },
                "ratio" : 80
            }, {
                "id": 102,
                "title": "Rémi",
                "path": "img/faces/02.svg",
                "position": {
                    "x": 12,
                    "y": 12
                },
                "ratio" : 80
            }, {
                "id": 103,
                "title": "Florian",
                "path": "img/faces/03.svg",
                "position": {
                    "x": 12,
                    "y": 12
                },
                "ratio" : 78
            }, {
                "id": 104,
                "title": "François",
                "path": "img/faces/04.svg",
                "position": {
                    "x": 12,
                    "y": 13
                },
                "ratio" : 78
            }
        ]
    }, {
        "title": "eyes",
        "order": 4,
        "elements": [
            {
                "id": 301,
                "title": "nothing",
                "path": "img/eyes/01.svg",
                "position": {
                    "x":53,
                    "y":49
                },
                "ratio": 80
            }, {
                "id": 302,
                "title": "glasses 1",
                "path": "img/eyes/02.svg",
                "position": {
                    "x":33,
                    "y":43
                },
                "ratio": 85
            }, {
                "id": 303,
                "title": "glasses 2",
                "path": "img/eyes/03.svg",
                "position": {
                    "x":35,
                    "y":42
                },
                "ratio": 82
            }, {
                "id": 304,
                "title": "surprise",
                "path": "img/eyes/04.svg",
                "position": {
                    "x":61,
                    "y":26
                },
                "ratio": 75
            }, {
                "id": 305,
                "title": "surprise",
                "path": "img/eyes/05.svg",
                "position": {
                    "x":56,
                    "y":45
                },
                "ratio": 30
            }, {
                "id": 306,
                "title": "bored",
                "path": "img/eyes/06.svg",
                "position": {
                    "x":53,
                    "y":50
                },
                "ratio": 40
            }, {
                "id": 307,
                "title": "happy",
                "path": "img/eyes/07.svg",
                "position": {
                    "x":50,
                    "y":45
                },
                "ratio": 60
            }
        ]
    }, {
        "title": "mouth",
        "order": 5,
        "elements": [
            {
                "id": 401,
                "title": "smile",
                "path": "img/mouths/01.svg",
                "position": {
                    "x":66,
                    "y":67
                },
                "ratio": 20
            }, {
                "id": 402,
                "title": "unhappy",
                "path": "img/mouths/02.svg",
                "position": {
                    "x":66,
                    "y":67
                },
                "ratio": 20
            }, {
                "id": 403,
                "title": "weird",
                "path": "img/mouths/03.svg",
                "position": {
                    "x":58,
                    "y":63
                },
                "ratio": 60
            }, {
                "id": 404,
                "title": "smile",
                "path": "img/mouths/04.svg",
                "position": {
                    "x":65,
                    "y":68
                },
                "ratio": 40
            }, {
                "id": 405,
                "title": "weird lite",
                "path": "img/mouths/05.svg",
                "position": {
                    "x":60,
                    "y":66
                },
                "ratio": 50
            }, {
                "id": 406,
                "title": "tongue",
                "path": "img/mouths/06.svg",
                "position": {
                    "x":45,
                    "y":65
                },
                "ratio": 50
            }, {
                "id": 407,
                "title": "mouth in your ass",
                "path": "img/mouths/07.svg",
                "position": {
                    "x":60,
                    "y":66
                },
                "ratio": 40
            }
        ]
    }, {
        "title" : "body",
        "order": 2,
        "elements": [
            {
                "id": 1,
                "title": "Tie shirt",
                "path": "img/bodies/01.svg",
                "position": {
                    "x": 25,
                    "y": 55
                },
                "ratio" : 50
            }, {
                "id": 2,
                "title": "Tie shirt",
                "path": "img/bodies/02.svg",
                "position": 
                {
                    "x": 25,
                    "y": 55
                },
                "ratio" : 50
            },{
                "id": 3,
                "title": "Polo",
                "path": "img/bodies/03.svg",
                "position": {
                    "x": 25,
                    "y": 55
                },
                "ratio" : 50
            },{
                "id": 4,
                "title": "Shirt",
                "path": "img/bodies/04.svg",
                "position": {
                    "x": 28,
                    "y": 53
                },
                "ratio" : 50
            }
        ]
    }, {
        "title" : "hats",
        "order": 6,
        "elements": [
            {
                "id": 601,
                "title": "None",
            }, {
                "id": 602,
                "title": "Fireman",
                "path": "img/hats/01.svg",
                "position": 
                {
                    "x": 5,
                    "y": -10
                },
                "ratio" : 120
            }, {
                "id": 603,
                "title": "Mario",
                "path": "img/hats/02.svg",
                "position": 
                {
                    "x": 3,
                    "y": -7
                },
                "ratio" : 148
            }, {
                "id": 604,
                "title": "Paper boat",
                "path": "img/hats/03.svg",
                "position": 
                {
                    "x": 35,
                    "y": 2
                },
                "ratio" : 40
            }, {
                "id": 605,
                "title": "Sombrero",
                "path": "img/hats/04.svg",
                "position": 
                {
                    "x": 0,
                    "y": -15
                },
                "ratio" : 148
            }
        ]
    }, {
        "title": "background",
        "order": 1,
        "elements": [
            {
                "id": 205,
                "title": "indigo",
                "color": "#3f51b5"
            }, {
                "id": 206,
                "title": "blue",
                "color": "#2196f3"
            }, {
                "id": 207,
                "title": "light-blue",
                "color": "#03a9fa"
            }, {
                "id": 208,
                "title": "cyan",
                "color": "#00bcd4"
            }, {
                "id": 209,
                "title": "teal",
                "color": "#009688"
            }, {
                "id": 210,
                "title": "green",
                "color": "#4caf50"
            }, {
                "id": 211,
                "title": "light-green",
                "color": "#8bc34a"
            }, {
                "id": 212,
                "title": "lime",
                "color": "#cddc39"
            }, {
                "id": 213,
                "title": "yellow",
                "color": "#ffeb3b"
            }, {
                "id": 214,
                "title": "amber",
                "color": "#ffc107"
            }, {
                "id": 215,
                "title": "orange",
                "color": "#ff9800"
            }, {
                "id": 216,
                "title": "deep-orange",
                "color": "#ff5722"
            }, {
                "id": 201,
                "title": "red",
                "color": "#f44336"
            }, {
                "id": 202,
                "title": "pink",
                "color": "#e91e63"
            }, {
                "id": 203,
                "title": "purple",
                "color": "#9c27b0"
            }, {
                "id": 204,
                "title": "deep-purple",
                "color": "#673ab7"
            }, {
                "id": 217,
                "title": "brown",
                "color": "#795548"
            }, {
                "id": 218,
                "title": "grey",
                "color": "#9e9e9e"
            }, {
                "id": 219,
                "title": "blue-grey",
                "color": "#607d8d"
            }, {
                "id": 220,
                "title": "black",
                "color": "#000000"
            }, {
                "id": 221,
                "title": "white",
                "color": "#ffffff"
            }, {
                "id": 222,
                "title": "transparent",
                "color": ""
            }
        ]
    }]
}