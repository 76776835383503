import { manager } from './modules/manager.js';
import { avatar } from './avatar.js';

// register app
manager.registerApp('tiltomaton');
manager.registerModule('avatar', avatar, { expose: true });


const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
	  // [::1] is the IPv6 localhost address.
	  window.location.hostname === '[::1]' ||
	  // 127.0.0.1/8 is considered localhost for IPv4.
	  window.location.hostname.match(
		/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
	  )
  );
  
if('serviceWorker' in navigator && !isLocalhost) {

	/*navigator.serviceWorker.getRegistrations()
	.then(registrations => {
    	for(let registration of registrations) {
        	registration.unregister();
    	}
	});*/

	navigator.serviceWorker.register('/service-worker.js')
	.then(reg => {
		if(reg.installing) { console.log('Service worker installing'); }
		else if(reg.waiting) { console.log('Service worker installed'); }
		else if(reg.active) { console.log('Service worker active'); }
	}).catch(function(error) {
		// registration failed
		console.log(`Registration failed with ${error}`);
	});
}
