import data from '../../data.js'

export class Avatar {

    run() {
        this.init();
    }

    init() {
        this.baseUrl = document.location.origin;

        this.loadImages().then(() => {

            this.canvas = document.querySelector('#avatar');
            this.ctx = this.canvas.getContext('2d');
            
            document.querySelector('.loader').remove();
            this.canvas.classList.add('fadeIn');

            this.current = [];
            data.options.forEach(item => {
                this.current.push({title: item.title, order: item.order, element: item.elements[0]});
            });
                
            this.sizes = { 
                width: this.canvas.offsetWidth,
                height: this.canvas.offsetHeight,
                ratio: 300 / this.canvas.offsetWidth
            }
    
            this.canvas.width = this.sizes.width;
            this.canvas.height = this.sizes.height;
        
            this.renderOptions()
            .then(() => {
                document.querySelector(".options li:first-child button").click();
            });
    
            this.handleOption();
            this.handleSelect();
            this.handleDownload();
            this.handleDownload4K();
            this.handleControls();
    
            this.renderAvatar();
        });

    }

    handleOption() {
        document.querySelectorAll('[data-option]').forEach(element => {
            element.addEventListener('click', e => {

                this.option = e.currentTarget.getAttribute('data-option');
                e.currentTarget.closest('ul').querySelectorAll('button').forEach(item => { item.classList.remove('active') });
                e.currentTarget.classList.add('active');

                if(this.option === 'background') {
                    document.querySelectorAll('.controls button').forEach(item => item.setAttribute('disabled', 'disabled'));
                }
                else {
                    document.querySelectorAll('.controls button').forEach(item => item.removeAttribute('disabled'));
                }
            })
        })
    }

    handleSelect() {

        document.querySelectorAll('[data-select]').forEach(item => item.addEventListener('click', e => {
            
            const option = this.getOption(this.option); 
            let current = this.current.find(x => x.title === this.option);


            const index = option.elements.findIndex(x => x.id === current.element.id);

            if(e.currentTarget.getAttribute('data-select') === 'next') {
                if(index < option.elements.length - 1) { 
                    current.element = option.elements[index + 1];
                }
                else { 
                    current.element = option.elements[0];
                }
            }
            else {
                if(index > 0) { 
                    current.element = option.elements[index - 1];
                }
                else { 
                    current.element = option.elements[option.elements.length - 1];
                }
            }

            this.renderAvatar();
        }));
    }

    handleDownload() {

        document.querySelector('[data-action=download]').addEventListener('click', e => {

            this.canvas.toBlob(blob => {
                const url = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute("download", "avatar.png");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        });
    }

    handleDownload4K() {

        document.querySelector('[data-action=download4K]').addEventListener('click', e => {

            this.canvas.width = 1200
            this.canvas.height = 1200
            this.ctx.scale(4,4)
            this.renderAvatar()

            this.canvas.toBlob(blob => {
                const url = URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute("download", "avatar.png");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });

            this.canvas.width = 300
            this.canvas.height = 300
            this.ctx.scale(1,1)
            this.renderAvatar()
        });
    }



    handleControls() {

        document.querySelectorAll('[data-zoom]').forEach(item => item.addEventListener('click', e => {
            
            const value = e.currentTarget.getAttribute('data-zoom');
            const element = this.current.find(x => x.title === this.option).element;
            
            if(value === 'in') {
                element.ratio += 1; 
            }
            else {
                if(element.ratio > 0) {
                    element.ratio -= 1; 
                }
            }
            this.renderAvatar();
        }));

        const updatePosition = direction => {

            const element = this.current.find(x => x.title === this.option).element;

            switch(direction) {
                case 'up':
                    element.position.y -= 1;
                break;
                case 'down':
                    element.position.y += 1;
                break;
                case 'left':
                    element.position.x -= 1;
                break;
                case 'right':
                    element.position.x += 1;
                break;
            }
            this.renderAvatar();
        }

        document.querySelectorAll('[data-direction]').forEach(item => {
            
            let intervalId, timeoutId = null;
            
            item.addEventListener('mousedown', e => {
            
                const direction = e.currentTarget.getAttribute('data-direction');
            
                updatePosition(direction);

                timeoutId = setTimeout(() => {
                    intervalId = setInterval(() => { 
                        updatePosition(direction) 
                    }, 100);
                }, 500);
            });

            item.addEventListener('mouseup', () => { 
                clearInterval(intervalId); 
                clearTimeout(timeoutId); 
            });

        });
    }

    getOption(option, index = null) {
        if(index !== null) {
            return data.options.find(x => x.title === option).elements[index];
        }
        else {
            return data.options.find(x => x.title === option);
        }
    }

    renderOptions() {

        return new Promise((resolve, reject) => {
            data.options.forEach(item => {
    
                const ul = document.querySelector(".options ul");
    
                const li = document.createElement('li');
                const button = document.createElement('button');
                button.className = 'btn light-green darken-2 waves-effect waves-light';
                button.setAttribute('data-option', item.title);
                button.innerHTML = item.title;
    
                li.appendChild(button);
                ul.appendChild(li);
            });
            resolve();
        })

    }

    renderAvatar() {

        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.renderBackground();

        let parts = this.current.filter(x => x.title !== 'background');
        parts = parts.sort((a,b) => a.order > b.order ? 1 : -1);

        parts.forEach(part => {
            this.draw(part);
        });
    }


    loadImages() {

        let promises = [];

        data.options.forEach(option => {

            option.elements.forEach(element => {
                if(element.path) {
                    promises.push(this.loadImage(element, option));
                }
            });
        });

        return Promise.all(promises);
    }

    loadImage(element) {

        return new Promise((resolve, reject) => {
            const img = new Image();
            img.addEventListener('load', () => {
                element.img = img;
                resolve(element);
            });
            img.src = `${element.path}`;
        });
    }

    renderBackground() {

        const background = this.current.filter(x => x.title === 'background');
        if(background.length > 0) {
            const compositeOperation = this.ctx.globalCompositeOperation;
            this.ctx.globalCompositeOperation = 'destination-over';
            this.ctx.fillStyle = background[0].element.color;
            this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
            this.ctx.globalCompositeOperation = compositeOperation;
        }
    }

    draw(part) { 

        if(part.element.position) {
            const x = part.element.position.x * this.sizes.width / 100;
            const y = part.element.position.y * this.sizes.height / 100;
            
            const width = part.element.img.width / this.sizes.ratio;
            const height = part.element.img.height / this.sizes.ratio;
    
    
            if(part.element.ratio !== null) {                    
                this.ctx.drawImage(part.element.img, x, y, width * part.element.ratio / 100, height * part.element.ratio / 100);
            }
            else {
                this.ctx.drawImage(part.element.img, x, y, width, height);
            }
        }

    }
}

export const avatar = new Avatar();